<template>
  <div>
    <div class="checkbox flex items-center">
      <input
        :id="id"
        :required="required"
        :value="value"
        type="checkbox"
        :checked="checked"
        class="appearance-none border-gray-300 rounded-sm checked:bg-primary focus:ring-primary text-primary"
        @input.stop="handleChange"
      />
      <label v-if="label" :for="id" class="ml-3 block cursor-pointer">{{ label }}</label>
    </div>
    <small v-if="error" class="block text-red-500 italic text-sm">{{ error }}</small>
  </div>
</template>

<script>
export default {
  name: "CheckboxInput.vue",
  props: {
    id: {},
    value: {},
    checked: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => null,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    error: {
      type: String,
      default: () => null,
    },
  },
  methods: {
    handleChange() {
      this.$emit("input")
    },
  },
}
</script>

<style scoped></style>
