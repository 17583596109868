<template>
  <div>
    <TableComponent>
      <thead>
        <TableRow>
          <TableHead>
            <input
              class="appearance-none border-gray-300 rounded-sm checked:bg-primary focus:ring-primary text-primary"
              type="checkbox"
              :checked="areAllSelected"
              @change="toggleSelectAll"
            />
          </TableHead>
          <TableHead class="pl-0">
            <SecondaryButton
              v-if="selectedEmployeeIds.length > 0"
              compact
              class="-my-2"
              @click="showMultiDeleteConfirmation = true"
            >
              {{ $t("employees.delete_selected") }}
            </SecondaryButton>
            <span v-else>
              {{ $t("employees.employee") }}
            </span>
          </TableHead>
          <TableHead align="right">{{ $t("employees.source") }}</TableHead>
          <TableHead>{{ $t("general.team") }}</TableHead>
          <TableHead>{{ $t("employees.manager") }}</TableHead>
          <TableHead class="text-right">{{ $t("general.last_updated") }}</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </thead>
      <tbody>
        <TableRow v-if="isLoading" class="font-bold relative">
          <td colspan="6">
            <div class="flex w-full justify-center my-4">
              <span class="bg-transparent bg-red text-lg">{{
                $t("anniversaries.is-loading")
              }}</span>
            </div>
          </td>
        </TableRow>
        <TableRow v-else-if="!isLoading && !employeeList?.length" class="font-bold relative">
          <td colspan="6">
            <div class="flex w-full justify-center my-4">
              <span class="bg-transparent bg-red text-lg">{{
                $t("anniversaries.no-results")
              }}</span>
            </div>
          </td>
        </TableRow>
        <TableRow v-for="employee in employeeList" v-else :key="employee.id">
          <TableCell>
            <CheckboxInput
              :id="'checkbox-' + employee.id"
              :checked="selectedEmployeeIds.includes(employee.id)"
              :value="employee.id"
              @input="handleCheckboxInput(employee.id)"
            />
          </TableCell>
          <TableCell class="pl-0">
            <ActionLink :href="zRoute('employees.show', employee)">
              {{ employee.first_name + " " + employee.last_name }}
            </ActionLink>
            <div class="text-sm font-normal">
              <div>{{ employee.email }}</div>
              <div v-if="employee.employee_type" class="mt-1">
                {{ employee.employee_type }}
              </div>
            </div>
          </TableCell>
          <TableCell align="right">
            <LabelDefault :label="$t(`employees.source_${employee.source}`)" />
          </TableCell>
          <TableCell>
            <span class="capitalize">{{ employee.groupName || "-" }}</span>
          </TableCell>
          <TableCell>
            <ActionLink
              v-if="employee.managerName"
              :href="zRoute('employees.show', employee.manager_id)"
            >
              {{ employee.managerName }}
            </ActionLink>
          </TableCell>
          <TableCell align="right">
            {{ formatDateFromISO(employee.updated_at, "dd/LL/yyyy HH:mm") }}
          </TableCell>
          <TableCell buttons>
            <SecondaryButton square :href="zRoute('employees.show', employee)">
              <EditIcon class="w-4 h-4" />
              <span class="sr-only">{{ $t("general.edit") }}</span>
            </SecondaryButton>
            <SecondaryButton square @click="singleEmployeeToBeDeleted = employee">
              <TrashSolidIcon class="w-4 h-4" />
              <span class="sr-only">{{ $t("general.delete") }}</span>
            </SecondaryButton>
          </TableCell>
        </TableRow>
      </tbody>
    </TableComponent>
    <Pagination v-if="paginationData" :meta="paginationData" />

    <!-- Multiple Delete Confirmation -->
    <ModalConfirmation
      :show="showMultiDeleteConfirmation"
      :title="$t('employees.delete_multiple_confirmation_title')"
      @close="showMultiDeleteConfirmation = false"
      @confirm="deleteSelectedEmployees"
    >
      <p
        v-html="
          $t('employees.delete_multiple_confirmation', {
            count: selectedEmployeeIds.length,
          })
        "
      ></p>
    </ModalConfirmation>
    <!-- SingleDeleteConfirmation -->
    <ModalConfirmation
      :show="!!singleEmployeeToBeDeleted"
      :title="$t('employees.delete_confirmation_title')"
      @close="singleEmployeeToBeDeleted = undefined"
      @confirm="removeEmployee"
    >
      <p v-html="$t('employees.delete_confirmation')"></p>
    </ModalConfirmation>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import ActionLink from "@/components/elements/links/ActionLink.vue"
import CheckboxInput from "./CheckboxInput.vue"
import Pagination from "@/components/elements/pagination/Pagination.vue"
import TableRow from "../elements/table/TableRow.vue"
import TableHead from "../elements/table/TableHead.vue"
import TableCell from "../elements/table/TableCell.vue"
import TableComponent from "../elements/table/TableComponent.vue"
import ModalConfirmation from "../elements/modals/ModalConfirmation.vue"
import SecondaryButton from "@/components/elements/buttons/SecondaryButton.vue"
import { EditIcon, TrashSolidIcon } from "@/components/icons"
import { formatDateFromISO } from "@/helpers/date-helpers"
import { successToast } from "@/helpers/toasts"
import httpClient from "@/helpers/http"
import LabelDefault from "../elements/labels/LabelDefault.vue"

export default {
  name: "EmployeesList",
  components: {
    ActionLink,
    Pagination,
    TableRow,
    TableHead,
    TableCell,
    TableComponent,
    SecondaryButton,
    CheckboxInput,
    ModalConfirmation,
    EditIcon,
    TrashSolidIcon,
    LabelDefault,
  },
  props: {
    employeeList: {
      type: Array,
      default: () => [],
    },
    paginationData: Object,
    isLoading: Boolean,
  },
  setup() {
    const { t } = useI18n()
    return {
      t,
      formatDateFromISO,
    }
  },
  data() {
    return {
      showMultiDeleteConfirmation: false,
      singleEmployeeToBeDeleted: undefined,
      selectedEmployeeIds: [],
    }
  },
  computed: {
    areAllSelected() {
      return (
        this.employeeList &&
        this.employeeList.length &&
        this.employeeList.length === this.selectedEmployeeIds.length
      )
    },
  },
  methods: {
    deleteSelectedEmployees() {
      let { selectedEmployeeIds } = this
      httpClient
        .post(window.route("employees.truncate"), {
          employeeIds: selectedEmployeeIds,
        })
        .then(async () => {
          this.$emit("update")
          successToast({ message: this.t("messages.employees_deleted") })
        })
        .catch(err => console.error(err))
    },
    removeEmployee() {
      httpClient
        .delete(this.zRoute("employees.delete", this.singleEmployeeToBeDeleted))
        .then(() => {
          this.singleEmployeeToBeDeleted = undefined
          this.$emit("update")
          successToast({ message: this.t("messages.employee_deleted") })
        })
    },
    handleCheckboxInput(employeeId) {
      if (this.selectedEmployeeIds.includes(employeeId)) {
        this.selectedEmployeeIds = this.selectedEmployeeIds.filter(id => id !== employeeId)
      } else {
        this.selectedEmployeeIds.push(employeeId)
      }
    },
    toggleSelectAll() {
      if (this.areAllSelected) {
        this.selectedEmployeeIds = []
      } else {
        this.selectedEmployeeIds = this.employeeList.map(e => e.id)
      }
    },
  },
}
</script>

<style scoped></style>
